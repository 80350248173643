import React, { useState, useRef, useEffect } from "react";
import {
  useMediaQuery,
  useTheme,
  Box,
  Grid,
  Typography,
  Button,
  Link
} from "@mui/material";
import FlippingCardsKnowledgeEngine from "./FlippingCardsKnowledgeEngine";
import KnowledgeEngineSmall from "./KnowledgeEngineSmall";
import StatsMap from "../NewHomePagePhotos/BlueBackground1.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import anime from "animejs/lib/anime.es.js";

const KnowledgeEngineResponsive = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [animationsPlayed, setAnimationsPlayed] = useState(false);
  const sectionRef = useRef(null);
  const textRef5A = useRef(null);
  const textRef6 = useRef(null);
  const textRefs = useRef([
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !animationsPlayed) {
            setAnimationsPlayed(true);
            anime({
              targets: textRef5A.current,
              translateX: [100, 0],
              opacity: [0, 1],
              duration: 1200,
              easing: "easeOutExpo",
              delay: 400,
            });
            anime({
              targets: textRef6.current,
              scale: [0.9, 1.2],
              opacity: [0, 1],
              duration: 1400,
              easing: "easeOutQuad",
              delay: 500,
            });
            textRefs.current.forEach((ref, index) => {
              anime({
                targets: ref.current,
                scale: [0.9, 1.2],
                opacity: [0, 1],
                duration: 1400,
                easing: "easeOutQuad",
                delay: 500 + index * 150,
              });
            });
          }
        });
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [animationsPlayed]);

  return (
    <Box
      ref={sectionRef}
      sx={{
        backgroundImage: `url(${StatsMap})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        zIndex: -2,
      }}
    >
      <Box
        sx={{
          //backgroundColor: "rgba(108,145,194, 0.5)",
          backdropFilter: "blur(10px)",
          zIndex: -1,
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={12}>
            <Typography
              variant="h3"
              align="center"
              ref={textRef6}
              sx={{
                fontWeight: "bold",
                m: 3,
                color: theme.palette.primary.main,
                opacity: 0,
              }}
            >
              Knowledge Engine
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
                p: 4,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                ref={textRef5A}
                sx={{
                  borderRadius: 10,
                  textTransform: "none",
                  background: "quinary.main",
                  opacity: 0,
                  "& .MuiTypography-root": {
                    marginRight: "0.5rem",
                  },
                  "&:hover": {
                    boxShadow: "0 0 10px #6C91C2",
                    backgroundColor: "quinary.main",
                    color: "secondary.main",
                  },
                  "&:hover .MuiSvgIcon-root": {
                    transform: "translateX(5px)",
                    transition: "transform 0.3s ease-in-out",
                    color: "secondary.main",
                  },
                }}
              >
                <Link
                  href="https://pub-api.wfyb.app"
                  sx={{
                    textDecoration: "none",
                    color: theme.palette.quinary.main,
                    fontWeight: "bold"
                  }}
                >
                <Typography
                  //variant="h6"
                  sx={{
                    fontWeight: "bold"
                  }}
                >
                  Sign in to Knowledge Engine
                </Typography>
                </Link>
                <ArrowForwardIcon
                  sx={{
                    transition: "transform 0.3s ease-in-out",
                    mb: 0.6,
                  }}
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
        {matches ? <FlippingCardsKnowledgeEngine /> : <KnowledgeEngineSmall />}
      </Box>
    </Box>
  );
};

export default KnowledgeEngineResponsive;
