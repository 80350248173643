import { useRef, useEffect } from "react";
import anime from "animejs";
import {
  Container,
  Typography,
  Grid,
  Button,
  Box,
  useTheme,
  useMediaQuery,
  Link,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CardsSmallScreen from "./CardsSmallScreen";
import CardsLargeScreen from "./CardsLargeScreen";
import StatsMap from "../NewHomePagePhotos/BlueBackground1.png";

const FavPortal = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const textRef1 = useRef(null);
  const textRef2 = useRef(null);
  const textRef2A = useRef(null);
  const textRef2B = useRef(null);
  const textRef5A = useRef(null);
  const textRef8 = useRef(null);
  const componentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            anime({
              targets: textRef1.current,
              scale: [0.7, 1],
              opacity: [0, 1],
              duration: 1400,
              easing: "easeOutElastic",
              delay: 200,
            });

            anime({
              targets: textRef2.current,
              translateY: [100, 0],
              opacity: [0, 1],
              duration: 1000,
              easing: "easeOutExpo",
              delay: 100,
            });

            anime({
              targets: textRef2A.current,
              translateY: [110, 0],
              opacity: [0, 1],
              duration: 900,
              easing: "easeOutExpo",
              delay: 300,
            });

            anime({
              targets: textRef2B.current,
              translateY: [120, 0],
              opacity: [0, 1],
              duration: 800,
              easing: "easeOutExpo",
              delay: 500,
            });

            anime({
              targets: textRef5A.current,
              translateX: [100, 0],
              opacity: [0, 1],
              duration: 1200,
              easing: "easeOutExpo",
              delay: 400,
            });

            anime({
              targets: textRef8.current,
              scale: [0.8, 1],
              opacity: [0, 1],
              duration: 600,
              easing: "easeOutQuad",
              delay: 300,
            });

            anime({
              targets:
                ".animate-upwards1, .animate-upwards2, .animate-upwards3, .animate-upwards4",
              translateY: [100, 0],
              opacity: [0, 1],
              duration: 1000,
              easing: "easeOutExpo",
              delay: anime.stagger(200, { start: 500 }),
            });

            observer.unobserve(componentRef.current);
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentComponent = componentRef.current;

    if (currentComponent) {
      observer.observe(currentComponent);
    }

    return () => {
      // Use the captured value in the cleanup function
      if (currentComponent) {
        observer.unobserve(currentComponent);
      }
    };
  }, []);

  return (
    <Box
      ref={componentRef}
      sx={{
        position: "relative",
        width: "100%",
        p: { xs: 1, sm: 2 },
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${StatsMap})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -2,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(8,20,70,0.9)",
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          zIndex: 1,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          padding: theme.spacing(3),
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{
            display: "flex",
          }}
        >
          <Grid item xs={12} sm={6}>
            <Container>
              <Typography
                variant="h2"
                ref={textRef2}
                sx={{
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                  px: 6,
                  pt: 7,
                }}
              >
                Broker Portal
              </Typography>
            </Container>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
                p: 4,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                ref={textRef5A}
                sx={{
                  borderRadius: 10,
                  border: 1,
                  borderColor: "primary.main",
                  textTransform: "none",
                  background: "secondary.main",
                  "& .MuiTypography-root": {
                    marginRight: "0.5rem",
                  },
                  "&:hover": {
                    boxShadow: "0 0 10px #6C91C2",
                    backgroundColor: "quinary.main",
                    color: "secondary.main",
                  },
                  "&:hover .MuiSvgIcon-root": {
                    transform: "translateX(5px)",
                    transition: "transform 0.3s ease-in-out",
                    color: "secondary.main",
                  },
                }}
              >
                <Link
                  href="https://brokers.w"
                  sx={{
                    textDecoration: "none",
                    color: theme.palette.quinary.main,
                    fontWeight: "bold",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Sign in to Broker Portal
                  </Typography>
                </Link>
                <ArrowForwardIcon
                  sx={{ transition: "transform 0.3s ease-in-out", mb: 0.6 }}
                />
              </Button>
            </Box>
          </Grid>

          <Grid
            container
            item
            spacing={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid
              item
              xs={12}
              lg={6}
              sx={{
                fontSize: {
                  xs: "1em",
                  md: "1.5em",
                },
              }}
            >
              <Container sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  ref={textRef1}
                  sx={{
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "center",
                      xl: "justify",
                    },
                    lineHeight: "1.5",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    color: theme.palette.quinary.main,
                    borderRadius: 5,
                    p: {
                      xs: 0,
                      sm: 1,
                      md: 10,
                      lg: 12,
                    },
                  }}
                >
                  Get access to our proprietary Artificial Intelligence driven
                  search engine, Search over 200 boat listing sites using images
                  and requirements to find the best boats for your clients.
                  Manage your clients through the entire lifecycle of the boat
                  buying process, Keep track of requirements and preferences and
                  be alerted when a boat that may suit them comes up for sale.
                </Typography>
              </Container>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Container ref={textRef2A} sx={{ height: 450 }}>
                {/* Video works if the file is placed in the public folder with the following code: */}
                {/* <video
                  src="/WFYB Broker Portal Tutorial.mp4"
                  title="Local Video"
                  controls
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  loop
                >
                  Sorry, your browser does not support embedded videos.
                </video> */}
                <iframe
                  src="https://player.vimeo.com/video/936555258?h=12b18d116c"
                  title="Vimeo Video"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                  }}
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                >
                  Sorry, your browser does not support embedded videos.
                </iframe>
              </Container>
            </Grid>
          </Grid>
        </Grid>
        <Container>
          {matches ? <CardsLargeScreen /> : <CardsSmallScreen />}
        </Container>
      </Box>
    </Box>
  );
};

export default FavPortal;
